@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

.nav {
  padding-bottom: 0vh;
  padding-left: 10%;
  min-height: 45vh;
  padding-top: 10vh;
  height: auto;
  width: 100%;
  position: static;
  overflow: hidden;
  @include tablet {
    position: sticky;
    top: 0px;
    width: 42%;
    height: 100vh;
    padding-bottom: 7.5vh;
    padding-left: 5%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__logo {
    overflow: hidden;
    min-height: 2em;
  }
  &__title {
    font-size: 1em;
    transition: color 0.5s;

    &-span {
      color: $secondary-text-color;
    }
    &:hover {
      color: #999;
      transition: color 0.5s;
    }
  }
  &__link {
    overflow: hidden;
    // min-height: 2em;
  }
  &__link-text {
    font-size: 1.1em;
    font-weight: 700;
    line-height: 100%;
    padding-bottom: 1.5vh;
    letter-spacing: -0.025em;
    transition: color 0.5s;

    &:hover {
      color: rgba(17, 17, 17, 0.5);
      transition: color 0.5s;
    }
  }
  &__spacer {
    height: 6vh;
    @include tablet {
      flex: 1;
    }
  }
  &__spacer2 {
    height: 6vh;
    border-bottom: 2px solid $secondary-text-color;
    width: 83%;
    @include tablet {
      border: none;
      flex: 1;
    }
  }
}
