@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

.contact {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  @include desktop {
    width: 80%;
  }
  &__title {
    margin-top: 1.2em;
    font-size: 1em;
    line-height: 1.5;
    font-weight: 600;
    letter-spacing: -0.02em;
    @include tablet {
      font-size: 1em;
    }
    &-span {
      color: $secondary-text-color;
      font-weight: 200;
      position: relative;
      top: -0.3em;
      font-size: 0.5em;
      padding-right: 15px;
    }
  }
  &__paragraph {
    margin-top: 3em;
    line-height: 1.6;
    width: 70%;
    @include tablet {
      width: 100%;
    }
  }
  &__links {
    margin-top: 2em;
    color: $primary-link-hover-color;
    display: flex;
    justify-content: space-between;
    width: 75%;
    background-color: transparent;
    margin-bottom: 2px;
    font-size: 0.6em;

    & a:hover {
      border-bottom: 2px solid rgba(137, 196, 244, 1);
      margin-bottom: -2px;
    }
  }
  &__text-container {
    overflow: hidden;
    @include tablet {
      max-width: 70%;
    }
  }
}
