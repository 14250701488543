//media mixins

@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1280px) {
    @content;
  }
}

//font mixins
@mixin h2-styles {
  font-size: 1.5em;
  line-height: 160%;
}
