@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

.main {
  @include tablet {
    display: flex;
    flex-direction: row;
  }
}

.contents {
  padding-left: 10%;
  @include tablet {
    padding-left: 0;
    width: 58%;
  }
}
