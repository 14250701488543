@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

.projects {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  gap: 2em;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  @include tablet {
    width: 90%;
  }
  @include desktop {
    width: 70%;
  }
}
.project__link-container {
  width: 90%;
}
.project__container {
  position: relative;
  width: 100%;
  height: 10em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @include tablet {
    height: 12em;
  }
  @include desktop {
    height: 14em;
  }
}

.project__img {
  height: 6.4em;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  @include tablet {
    height: 8em;
  }
  @include desktop {
    height: 8.5em;
  }
}
.project__icon-container {
  position: absolute;
  z-index: 20;
  bottom: 5%;
  left: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.project__name {
  font-size: 0.9em;
  background-color: $primary-background-color;
  padding: 0.5em 1em;
  border-radius: 20px;
  font-weight: 400;
  letter-spacing: -0.1em;
  @include tablet {
    font-size: 1em;
  }
}

.project__eye {
  width: 2em;
  height: 2em;
  border-radius: 100%;
  background-color: $primary-background-color;
  display: none;
  align-items: center;
  justify-content: center;
  @include tablet {
    display: flex;
  }
}

// project hover animation changes body background color
//adda
body:has(#project-container-0:hover) {
  @include tablet {
    background: #deaacb;
    transition: background 0.7s ease-in;
  }
}
//dawat
body:has(#project-container-1:hover) {
  @include tablet {
    background: #f8e299;
    transition: background 0.7s ease-in;
  }
}
//portfolio
body:has(#project-container-2:hover) {
  @include tablet {
    background: #cacfd3;
    transition: background 0.7s ease-in;
  }
}
//instock
body:has(#project-container-3:hover) {
  @include tablet {
    background: #5665dd;
    transition: background 0.7s ease-in;
  }
}
