@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

.work {
  width: 100%;
  background-color: $primary-background-color;
  &__header {
    overflow: hidden;
  }
  &__title {
    width: 100%;
    font-size: 4em;
    line-height: 160%;
    text-align: center;
    color: $primary-background-color;
    @include tablet {
      font-size: 9em;
    }
    @include desktop {
      font-size: 12em;
    }

    &-container {
      display: flex;
      justify-self: center;
      align-items: center;
      height: 75vh;
      @include tablet {
        height: 60vh;
      }
      @include desktop {
        height: 50vh;
      }
    }
  }
  &__image {
    width: 80%;
    border-radius: 20px;

    &-container {
      margin-top: 2em;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
  }
  &__info {
    margin-left: 10%;
    margin-right: 10%;
    @include tablet {
      position: relative;
      margin-top: 2em;
    }
  }

  //demo container

  &__demo-container {
    display: flex;
    margin-top: 2em;
    justify-content: space-around;
    margin-bottom: 3em;
    @include tablet {
      margin-top: 0;
      justify-content: flex-end;
      align-items: center;
      margin-left: 10%;
      margin-right: 10%;
      gap: 2rem;
    }
  }
  // &__demo-link {
  // }
  //build styling
  &__build {
    margin-top: 2em;
    @include tablet {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    &-title {
      font-size: 1.2em;
      letter-spacing: -0.11em;
      @include tablet {
        display: none;
      }
      &-tablet {
        display: none;
        @include tablet {
          display: inline-block;
          font-size: 1.2em;
          font-weight: 700;
          line-height: 100%;
          padding-bottom: 1.5vh;
          letter-spacing: -0.025em;
        }
      }
    }
    &-text {
      line-height: 1.6;
      @include tablet {
        margin: 0;
        margin-bottom: 1em;
      }
      &-container {
        @include tablet {
          width: 70%;
        }
      }
    }
    &-icons {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      gap: 0.5em;
    }
    &-icon {
      filter: grayscale(100);
      height: 1.5em;
      @include tablet {
        height: 2em;
      }
      &:hover {
        filter: none;
      }
    }
  }
  //features styling
  &__features {
    margin-top: 2em;
    @include tablet {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    &-title {
      font-size: 1.2em;
      letter-spacing: -0.11em;
      @include tablet {
        display: none;
      }
      &-tablet {
        display: none;
        @include tablet {
          display: inline-block;
          font-size: 1.2em;
          font-weight: 700;
          line-height: 100%;
          padding-bottom: 1.5vh;
          letter-spacing: -0.025em;
        }
      }
    }
    &-text-container {
      @include tablet {
        width: 70%;
      }
    }
    &-text {
      margin-bottom: 1.5em;
      @include tablet {
        margin-top: 0;
        font-size: 0.7em;
        margin-bottom: 1em;
      }
    }
    &-image {
      display: none;

      @include tablet {
        display: block;
        height: 23em;
        margin: 0;
      }
      @include desktop {
        height: 27em;
      }
      &-mobile {
        height: 18em;

        @include tablet {
          display: none;
        }
      }

      &-container {
        width: 100%;
        height: 10em;
        display: flex;
        justify-content: center;
        align-items: center;
        @include tablet {
          height: 15em;
          width: 100%;
        }
      }
    }
  }
  //outcome styling
  &__outcome {
    margin-top: 2em;

    @include tablet {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    &-title {
      font-size: 1.2em;
      letter-spacing: -0.11em;
      @include tablet {
        display: none;
      }
      &-tablet {
        display: none;
        @include tablet {
          display: inline-block;
          font-size: 1.2em;
          font-weight: 700;
          line-height: 100%;
          padding-bottom: 1.5vh;
          letter-spacing: -0.025em;
        }
      }
    }
    &-text {
      @include tablet {
        font-size: 0.7em;
        margin-top: 0;
        margin-bottom: 1em;
      }
      &-container {
        @include tablet {
          width: 70%;
        }
      }
    }
  }
}

.row-title {
  overflow: hidden;
}

.work__overlay {
  display: none;
  @include tablet {
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 2;
  }
}
