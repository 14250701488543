@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;

.hero {
  height: auto;
  min-height: 55vh;
  overflow: hidden;

  @include tablet {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: center;
  }
  &__title {
    font-size: 1.5em;
    line-height: 160%;
    &-span {
      color: $secondary-text-color;
    }
    &-container {
      overflow: hidden;
    }
  }
}
