@use "./colors" as *;
@use "./mixins" as *;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600;700&display=swap");
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden; //side scroll disable for mobile
}
html.w-mod-touch * {
  background-attachment: scroll !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  background-color: $primary-background-color;
  color: $iprimary-text-color;
  line-height: 1.5;
  font-size: 5.5vw;
  transition: background 1s ease-out;
  overflow-x: hidden; //side scroll disable for mobile

  @include tablet {
    font-size: 2.5vw;
    overflow-x: visible; //to ofset the hidden property for the navbar to
  }
  @include desktop {
    font-size: 2vw;
  }
}
h1 {
  margin-top: 0em;
  margin-bottom: 0em;
  font-size: 1.8em;
  line-height: 1;
  font-weight: 500;
  letter-spacing: -0.02em;
}

h2 {
  margin-top: 0em;
  margin-bottom: 0em;
  font-size: 1.6em;
  line-height: 1;
  font-weight: 600;
  letter-spacing: -0.02em;
}

h3 {
  margin-top: 0em;
  margin-bottom: 0em;
  font-size: 1.4em;
  line-height: 1;
  font-weight: 500;
  letter-spacing: -0.015em;
}

h4 {
  margin-top: 0em;
  margin-bottom: 0em;
  font-size: 1.2em;
  line-height: 1;
  font-weight: 500;
  letter-spacing: -0.01em;
}

h5 {
  margin-top: 0em;
  margin-bottom: 0em;
  font-size: 1em;
  line-height: 1;
  font-weight: 500;
}

h6 {
  margin-top: 0em;
  margin-bottom: 0em;
  font-size: 0.75em;
  line-height: 1;
  font-weight: 300;
  text-transform: uppercase;
}

p {
  margin-bottom: 0px;
  font-size: 0.7em;
}

a {
  text-decoration: none;
  color: inherit;
}

// @media screen and (max-width: 479px) {
//   body {
//     font-size: 4vw;
//   }
// }
